import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    clickSubmit(_event) {
        const submit = this.element.querySelector("input[type='submit']") || this.element.querySelector("button[type='submit']")
        if (submit) submit.click()
    }

    debounceClickSubmit(_event) {
        if (this.debounceClickSubmitEvent) {
            clearTimeout(this.debounceClickSubmitEvent)
        }

        this.debounceClickSubmitEvent = setTimeout(() => {
            const submit = this.element.querySelector("input[type='submit']") || this.element.querySelector("button[type='submit']")
            if (submit) submit.click()
        }, 300)
    }
}
